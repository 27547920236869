<mat-tab-group (selectedTabChange)="onTabChanged($event);" [(selectedIndex)]="selectedTabIndex">
    <mat-tab label="Carte">
        <app-map *ngIf="raceId" class="map" [LatLngTuple]="LatLngTuple" [Positions]="Positions" [Riders]="Riders"></app-map>
        <div class="divButton clearfix" >
            <button [disabled]="btnUnFollowDisable" mat-raised-button color="primary" (click)="unFollow()" class="float-left">Ne plus suivre</button>
        </div>
    </mat-tab>
    <mat-tab label="Coureurs">
        <table mat-table [dataSource]="Riders" class="mat-elevation-z8">

            <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->
          
            <!-- Position Column -->
            <ng-container matColumnDef="bib">
              <th mat-header-cell *matHeaderCellDef> No. </th>
              <td mat-cell *matCellDef="let element"> {{element.bib}} </td>
            </ng-container>
          
            <!-- Name Column -->
            <ng-container matColumnDef="firstname">
              <th mat-header-cell *matHeaderCellDef> Prénom </th>
              <td mat-cell *matCellDef="let element"> {{element.firstname}} </td>
            </ng-container>
          
            <!-- Weight Column -->
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Nom </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
            </ng-container>
          
            <!-- Symbol Column -->
            <ng-container matColumnDef="team">
              <th mat-header-cell *matHeaderCellDef> Equipe </th>
              <td mat-cell *matCellDef="let element"> {{element.team}} </td>
            </ng-container>

            <ng-container matColumnDef="follow">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" (click)="follow(element._id)" class="follow"> Suivre </td>
            </ng-container>
          
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
    </mat-tab>
    <mat-tab label="Réglages">
        <div class="divButton clearfix" >
            <mat-form-field>
                <mat-label>Mise à jour</mat-label>
                <mat-select [(value)]="selected" (selectionChange)="getRidersPosition()">
                    <mat-option value="5">5s</mat-option>
                    <mat-option value="10">10s</mat-option>
                    <mat-option value="15">15s</mat-option>
                </mat-select>
            </mat-form-field>
            <br/><br/>
            <button mat-raised-button color="primary" (click)="edit()" *ngIf="auth.user$ | async as user">Modifier la course</button>
        </div>
    </mat-tab>
</mat-tab-group>

  