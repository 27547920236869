import { Component, Input, OnChanges, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { CommonModule } from '@angular/common'
import { LeafletModule } from '@asymmetrik/ngx-leaflet'
import * as Leaflet from 'leaflet'
import { LeafletUtilsService } from '../../services/leaflet-utils.service'
import { Marker } from 'src/app/domain/marker'
import { Position } from 'src/app/domain/positions'
import { Rider } from 'src/app/domain/rider'

@Component({
  selector: 'app-map',
  standalone: true,
  imports: [CommonModule,LeafletModule],
  templateUrl: './map.component.html',
  styleUrl: './map.component.css'
})
export class MapComponent implements OnInit, OnChanges {
  @ViewChild('container', { read: ViewContainerRef }) _vcr;

  @Input() set LatLngTuple(value: [number,number][]) {
    if(value) {
      this.leafletUtilsService.drawCoordinates(value, this.map)
    }
 }

  @Input() set Positions(value: Position[]) {
    if(value) {
      this.positions = value
    }
  }

  @Input() set Riders(value: Rider[]) {
    if(value) {
      this.riders = value
    }
  }

  ngOnChanges() {
    if(this.positions && this.riders) {
      this.drawMarkers(this.positions, this.riders)
    }
  }

  map!: Leaflet.Map;
  markers: Leaflet.Marker[] = [];
  riders: Rider[]
  positions: Position[]
  mouseIsDown = false
  idTimeout = null;

  private openstreetmap = Leaflet.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution: '© <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
  })

  private esriWorldImagery = Leaflet.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
    attribution: '© Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
  })

  options = {
    layers: [
      this.openstreetmap
    ],
    zoom: 16,
    center: { lat: 28.626137, lng: 79.821603 }
  }

  leafletLayersControl = {
    baseLayers: {
      'Open Street Map': this.openstreetmap,
      'ESRI Satellite Map': this.esriWorldImagery
    }
  }

  ngOnInit() {
  }

  constructor(
    private leafletUtilsService: LeafletUtilsService,
  ) { }

  onMapReady($event: Leaflet.Map) {
    this.map = $event;
    setTimeout(() => {
      this.map.invalidateSize();
    });
    this.map.on('contextmenu', (event) => {
      const popup = Leaflet.popup({
        closeOnClick: false
      })
      .setLatLng([event.latlng.lat, event.latlng.lng])
      .setContent('<a target="_blank" href="https://maps.google.com/?q=' + event.latlng.lat + ',' + event.latlng.lng + '">Voir dans Gmap</a>')
      .addTo(this.map);
      setTimeout(() => {
        this.map.closePopup(popup)
      },5000)
    });
  }

  mapClicked($event: any) { }

  mapMouseDown($event: any) {
    this.mouseIsDown = true;
    this.idTimeout = setTimeout(() => {
      if(this.mouseIsDown) {
        const popup = Leaflet.popup({
          closeOnClick: false
        })
        .setLatLng([$event.latlng.lat, $event.latlng.lng])
        .setContent('<a target="_blank" href="https://maps.google.com/?q=' + $event.latlng.lat + ',' + $event.latlng.lng + '">Voir dans Gmap</a>')
        .addTo(this.map);
        setTimeout(() => {
          this.map.closePopup(popup)
        },5000)
      }
    }, 1000);
  }

  mapMouseUp($event: any) {
    clearTimeout(this.idTimeout);
    this.mouseIsDown = false;
  }

  drawMarkers(positions: Position[], riders: Rider[]) {
    const markers: Marker[] = [];
    for(const position of positions) {
      const marker: Marker = {
        position: {
          lat: position.lat,
          lng: position.lon
        },
        draggable: false,
        rider: this.getRiderById(position.id),
        date: position.date,
        speed: position.speed,
        battery: position.battery
      }
      markers.push(marker);
    }
    this.leafletUtilsService.drawMarkers(this.map, markers, this._vcr);
  }

  getRiderById(id: string): Rider{
    return this.riders.find(rider => rider.code === id)
  }

}