import { ApplicationRef, ComponentRef, Injectable, ViewContainerRef } from '@angular/core';
import { Track, parseGPX } from "@we-gold/gpxjs"
import * as L from 'leaflet';
import 'beautifymarker';
import { Marker } from '../domain/marker';
import { PopupComponent } from '../components/popup/popup.component';
import { Rider } from '../domain/rider';
import { Subject, Subscription } from 'rxjs';

// Declare the leaflet module so we can modify it
declare module 'leaflet' {
  // We want to alter the control namespace
  namespace BeautifyIcon {
    // Define minimal type information for the coordinates function
    function icon(v: any);
  }
}

@Injectable({
  providedIn: 'root'
})
export class LeafletUtilsService {

  public followRiderIdChanged: Subject<string> = new Subject<string>();
  private followRiderId: string
  private viewContainerRef: ViewContainerRef
  private divRef: ComponentRef<unknown>[] = []
  private map: L.Map
  private markers: Marker[] = []
  private lmarkers: L.Marker[] = []

  constructor(
    private appRef: ApplicationRef) {
  }

  addLayers(layer: any) {
    L.control.layers(layer).addTo(this.map);
  }

  getFollowedRider(): string {
    return this.followRiderId
  }

  followRider(riderId: string) {
    this.followRiderId = riderId
    this.followRiderIdChanged.next(this.followRiderId)
  }

  unfollowRider() {
    this.followRiderId = null
    this.followRiderIdChanged.next(this.followRiderId)
  }

  generateMarker(data: Marker) {

    const options = {
      isAlphaNumericIcon: true,
      text: data.rider?.bib,
      borderColor: data.rider?.color || '#00ABDC',
      textColor: data.rider?.color || '#00ABDC',
      innerIconStyle: 'margin-top:0;'
    };

    const markerPopup = this.compilePopup(PopupComponent, 
      (c: any) => {
        c.instance.customText = this.formateName(data.rider.firstname,data.rider.name,data.rider.team, data.rider.bib)
        c.instance.speed = data.speed
        c.instance.riderId = data.rider._id
        c.instance.lat = data.position.lat
        c.instance.lon = data.position.lng
        c.instance.date = data.date
        c.instance.battery = data.battery
      }
    );

    return L.marker(data.position, { draggable: data.draggable, icon: L.BeautifyIcon.icon(options),  })
      .on('click', (event) => this.clicEvent(event, data))
      .on('dragend', (event) => this.dragEvent(event, data))
      .bindPopup(markerPopup);
  }

  formateName(firstname: string, name: string, team: string, bib: number) {
    if(!firstname && ! name && !team) {
      return "unknown"
    }
    if (team) {
      return firstname + " " + name + " / " + team + " - " + bib;
    }
    return firstname + " " + name + " - " + bib;
  }

  // followMarker(event,data) {
    
  // }

  clicEvent(event,data) {
    //console.log(event)
  }

  dragEvent(event,data) {
    //console.log(event)
  }

  displayGPX(gpxContent, map: L.Map) {
    const [parsedFile, error] = parseGPX(gpxContent)
    if(error) {
      throw error
    }
    for(const track of parsedFile.tracks) {
      this.drawTrack(track, map)
    }
  }

  clearPopups() {
    for(const elem of this.divRef) {
      const div = elem.location.nativeElement as HTMLElement
      if(div.checkVisibility()) {
        setTimeout(()=>elem.destroy(),11000)
      } else {
        elem.destroy()
      }
    }
  }

  drawMarkers(map: L.Map, markers: Marker[], viewContainerRef: ViewContainerRef) {
    this.viewContainerRef = viewContainerRef;
    this.map = map;
    
    this.removeMarkers()
    this.clearPopups()
    this.markers.push(...markers)

    for (let data of markers) {
      const marker = this.generateMarker(data);
      marker.addTo(map)
      this.lmarkers.push(marker)
    }

    if(this.followRiderId) {
      map.panTo(markers.find(m => m.rider._id === this.followRiderId).position)
    }
  }

  redrawMarker() {
    this.removeMarkers()
    for (let data of this.markers) {
      const marker = this.generateMarker(data);
      marker.addTo(this.map)
    }
  }

  removeMarkers() {
    for(const marker of this.lmarkers) {
      if(marker.isPopupOpen()) {
        setTimeout(()=>marker.removeFrom(this.map),5000)
      } else {
        marker.removeFrom(this.map)
      }
    }
    this.lmarkers = []
  }

  drawTrack(track: Track, map: L.Map) {
    let coordinates: [number,number][] = track.points.map(p => [p.latitude, p.longitude]);
    this.drawCoordinates(coordinates, map)
  }

  drawCoordinates(coordinates: [number,number][], map: L.Map) {
    const polyline = L.polyline(coordinates, { weight: 6, color: 'darkred' }).addTo(map);
    // zoom the map to the polyline
    map.fitBounds(polyline.getBounds());
  }

  getCoordinates(gpxContent): [number,number][] {
    const [parsedFile, error] = parseGPX(gpxContent)
    if(error) {
      throw error
    }
    let allPoints: [number,number][] = []
    for(const track of parsedFile.tracks) {
      let points: [number,number][] = track.points.map(p => [p.latitude, p.longitude])
      allPoints.push(...points)
    }
    return allPoints
  }

  private uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
  }

  /**
   * Builds the referenced component so it can be injected into the 
   * leaflet map as popup.
   * Original code from:  https://github.com/darkguy2008/leaflet-angular4-issue/blob/master/src/app.ts
   * https://stackblitz.com/edit/angular-ivy-leafletjs-map-popup?file=src%2Fapp%2Fmap%2Fmap.component.ts
   */
  private compilePopup(component: any, onAttach): HTMLDivElement {
    const compRef = this.viewContainerRef.createComponent(component);
    this.divRef.push(compRef)
    // onAttach allows you to assign 
    if (onAttach)
      onAttach(compRef);

    compRef.onDestroy(() => this.appRef.detachView(compRef.hostView));

    let div = document.createElement('div');
    div.id = this.uuidv4()
    div.appendChild(compRef.location.nativeElement);
    return div;
  }
}
