<p>
  <b>{{customText}}</b>
  <br />
  Vitesse : {{formatSpeed(speed)}} km/h
  <br />
  Mise à jour : {{formatDate(date)}}
  <br />
  Batterie : {{battery}}
  <br /><br />
  <button *ngIf="riderId !== followedRiderId" (click)="follow()" type="button" class="btn btn-primary btn-sm">Suivre</button>
  <button *ngIf="riderId === followedRiderId" (click)="unFollow()" type="button" class="btn btn-primary btn-sm">Ne plus suivre</button>
  <button (click)="reach()" type="button" class="btn btn-primary btn-sm float-right">Rejoindre</button>
</p>
