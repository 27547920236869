import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Race } from '../domain/race';
import { Observable } from 'rxjs';
import { Position } from '../domain/positions';

@Injectable({
  providedIn: 'root'
})
export class RaceService {

  private baseUrl = "https://eu-west-2.aws.data.mongodb-api.com/app/application-0-jyrtipo/endpoint/"
  private raceUrl: string = this.baseUrl + "race"
  private gpxUrl: string = this.baseUrl + "gpx"
  private coordinatesUrl: string = this.baseUrl + "coordinates"
  private lastLocationsUrl: string = this.baseUrl + "lastLocations"

  constructor(private http: HttpClient) { }

  getOne(_id: string): Observable<Race> {
    return this.http.get<Race>(this.raceUrl + "?id="+_id)
  }

  get(): Observable<Race[]> {
    return this.http.get<Race[]>(this.raceUrl)
  }

  getCoordinates(_id: string): Observable<[number, number] []> {
    return this.http.get<[number, number] []>(this.coordinatesUrl + "?id="+_id)
  }

  getLastLocations(_id: string): Observable<Position[]> {
    return this.http.get<Position[]>(this.lastLocationsUrl + "?id="+_id)
  }

  create(race: Race) {
    return this.http.post(this.raceUrl, race).toPromise()
  }

  update(race: Race) {
    return this.http.put(this.raceUrl, race)
  }

  delete(_id: string) {
    return this.http.delete(this.raceUrl + "?id="+_id)
  }

  uploadGPX(_id: string, gpx: string, coordinates: [number, number][]) {
    return this.http.post(this.gpxUrl, {_id, gpx, coordinates}).toPromise()
  }

}
