import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RaceService } from 'src/app/services/race.service';
import { MapComponent } from './../../../components/map/map.component';
import { Race } from 'src/app/domain/race';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTabsModule } from '@angular/material/tabs';
import { MatIconModule } from '@angular/material/icon';
import { Subject, Subscription, timer } from 'rxjs';
import { tap, takeUntil, switchMap } from 'rxjs/operators';
import { Position } from 'src/app/domain/positions';
import { Rider } from 'src/app/domain/rider';
import { LeafletUtilsService } from 'src/app/services/leaflet-utils.service';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatTableModule} from '@angular/material/table';
import { AuthService } from '@auth0/auth0-angular';

interface Food {
  value: string;
  viewValue: string;
}


@Component({
  selector: 'app-race',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MapComponent,
    MatTabsModule,
    MatIconModule,
    MatSelectModule,
    MatFormFieldModule,
    MatTableModule
  ],
  templateUrl: './race-view.component.html',
  styleUrl: './race-view.component.scss'
})
export class RaceViewComponent implements OnInit, OnDestroy {
  private unsub = new Subject<void>();
  raceId = null
  race: Race=null
  dateFormat = "yyyy-MM-dd"
  language = "en"
  LatLngTuple!: [number,number][]
  Positions!: Position[]
  Riders!: Rider[]
  btnUnFollowDisable = true
  displayedColumns: string[] = ['bib', 'firstname', 'name', 'team', 'follow'];
  selectedTabIndex = 0

  selected = "5"
  private timer

  constructor(
    private raceService: RaceService,
    private route: ActivatedRoute,
    private router: Router,
    private leafletUtilsService: LeafletUtilsService,
    public auth: AuthService
  ) {}

  formatFormDate(date: Date) {
    return formatDate(date, this.dateFormat, this.language);
  }

  ngOnInit() {
    this.raceId = this.route.snapshot.paramMap.get('id');
    if(this.raceId) {
      this.raceService.getOne(this.raceId)
      .subscribe(race => {
        this.race = race
        this.Riders = this.race.riders.sort((a, b) => a.bib > b.bib ? 1 : -1)
      })
      this.raceService.getCoordinates(this.raceId)
      .subscribe(coordinates => {
        if(coordinates.length) {
          this.LatLngTuple = coordinates
        }
      })

      this.getRidersPosition()

      this.leafletUtilsService.followRiderIdChanged.subscribe((id)=> {
        if(id) {
          this.btnUnFollowDisable=false
        } else {
          this.btnUnFollowDisable=true
        }
      })
    }
  }

  edit() {
    this.router.navigate(['race-edit',this.raceId]);
  }

  unFollow() {
    this.leafletUtilsService.unfollowRider();
  }

  wait(seconds: number) {
    return new Promise((resolve) => {
      setTimeout(resolve, seconds * 1000);
    });
  }

  private async waitToStartPeriodicRefresh() {

    // todo add little randomization
    const seconds = new Date().getSeconds();
    let waitSeconds = 0;

    if(seconds<15) {
      waitSeconds = 15 - seconds;
    } else if(seconds<30) {
      waitSeconds = 30 - seconds;
    } else if(seconds<45) {
      waitSeconds = 45 - seconds;
    } else if(seconds<60) {
      waitSeconds = 60 - seconds;
    }

    await this.wait(waitSeconds);
  }

  async getRidersPosition() {

    const period = parseInt(this.selected) * 1000;

    if(this.timer) {
      clearInterval(this.timer)
    }

    // immediate
    this.refreshPositions();

    // await this.waitToStartPeriodicRefresh();

    this.timer = setInterval(async () => {
      this.refreshPositions();
    }, period)

    // if(this.subscription) {
    //   this.subscription.unsubscribe();
    //   this.unsub.next();
    //   this.unsub.complete();
    // }



    // this.subscription = timer(0,period).pipe(
    // //tap(()=>console.log(new Date())),
    // takeUntil(this.unsub),
    // switchMap(
    //   async () => {
    //     this.refreshPositions();
    //   }
    // )
    // ).subscribe();

  }

  onTabChanged(e) {
    //this.leafletUtilsService.clearPopup()
    //this.leafletUtilsService.redrawMarker()
  }

  private refreshPositions() {
    this.raceService.getLastLocations(this.raceId)
    .subscribe(positions => {
      this.Positions = positions;
    })
  }

  follow(riderId) {
    this.leafletUtilsService.followRider(riderId)
    this.selectedTabIndex = 0
  }

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }
  
}
