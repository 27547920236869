import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RaceService } from 'src/app/services/race.service';
import { MatListModule } from '@angular/material/list'
import { Router, RouterModule } from '@angular/router';
import { Race } from 'src/app/domain/race';
import {MatIconModule} from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-race-list',
  standalone: true,
  imports: [CommonModule, MatListModule, RouterModule,MatIconModule, MatButtonModule, MatInputModule],
  templateUrl: './race-list.component.html',
  styleUrl: './race-list.component.scss'
})
export class RaceListComponent implements OnInit {

  races: any

  constructor(
    private raceService: RaceService,
    private router: Router,
    public auth: AuthService
  ) {}

  ngOnInit() {
    this.raceService.get()
    .subscribe(races => {
      this.races = races.sort((a, b) => b.date > a.date ? 1 : -1);
    })
  }

  addRace() {
    this.router.navigate(['race']);
  }
}
