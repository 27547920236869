<div class="container">
    <input matInput placeholder="Search..." class="search">
    <button class="right" mat-raised-button color="primary" (click)="addRace()" *ngIf="auth.user$ | async as user">Ajouter</button>
</div>

<mat-nav-list>
    <mat-list-item class="item" [routerLink]="'/race/' + race._id" *ngFor="let race of races">
        {{race.name}} - 
        {{race.date}}
    </mat-list-item>
</mat-nav-list>

