<mat-tab-group>
  <mat-tab label="Race">

    <form [formGroup]="raceForm">

      <mat-form-field>
        <input matInput placeholder="Race name" formControlName="name">
      </mat-form-field>
  
      <app-map *ngIf="raceId" class="map" [LatLngTuple]="LatLngTuple"></app-map>
      <div>
        <label for="file">
          GPX file
        </label>
        <input id="file" class="file-upload" type="file" formControlName="gpx" (change)="handleFileInput($event.target.files)">
      </div>
  
      <mat-form-field>
        <input matInput type="date" formControlName="date">
      </mat-form-field>
  
      <mat-form-field>
        <textarea matInput placeholder="Details" formControlName="details"></textarea>
      </mat-form-field>
    
  </form>

  </mat-tab>
  <mat-tab label="Riders">

    <button mat-raised-button color="primary" (click)="addRider()">Ajouter</button>

    <div *ngFor="let rider of riders" class="rider">

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="code" [(ngModel)]="rider.code">
      </mat-form-field>

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="firstname" [(ngModel)]="rider.firstname">
      </mat-form-field>

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="name" [(ngModel)]="rider.name">
      </mat-form-field>

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="team" [(ngModel)]="rider.team">
      </mat-form-field>

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="bib" [(ngModel)]="rider.bib">
      </mat-form-field>

      <mat-form-field class="field-100 mat-form-field-no-padding">
        <input matInput placeholder="color" [(ngModel)]="rider.color">
      </mat-form-field>

      <button mat-icon-button color="primary" aria-label="Remove" (click)="removeRider(rider)">
        <mat-icon fxFlexAlign="center" class="">delete_outline</mat-icon>
      </button>

      <mat-divider></mat-divider>

    </div>
 

  </mat-tab>
</mat-tab-group>

<div class="divButton">
  <button mat-raised-button color="primary" *ngIf="raceId" (click)="back()">Retour</button>
  <button mat-raised-button color="primary" *ngIf="raceId" (click)="update()">Mettre à jour</button>
  <button mat-raised-button color="primary" *ngIf="!raceId" (click)="create()">Créer</button>
  <button mat-raised-button color="warn" *ngIf="raceId" (click)="delete()" class="float-right">Supprimer</button>
  <button mat-raised-button color="primary" *ngIf="raceId" (click)="duplicate()" class="float-right">Dupliquer</button>
</div>


