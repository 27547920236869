import {
  Routes,
} from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ExternalApiComponent } from './pages/external-api/external-api.component';
import { ErrorComponent } from './pages/error/error.component';
import { authGuardFn } from '@auth0/auth0-angular';
import { MapComponent } from './components/map/map.component';
import { RaceComponent } from './pages/race/race/race.component';
import { RaceListComponent } from './pages/race/race-list/race-list.component';
import { RaceViewComponent } from './pages/race/race-view/race-view.component';

export const routes: Routes = [
  {
    path: 'profile',
    component: ProfileComponent,
    //canActivate: [authGuardFn],
  },
  {
    path: 'external-api',
    component: ExternalApiComponent,
    //canActivate: [authGuardFn],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: '',
    component: RaceListComponent,
    pathMatch: 'full'
  },
  {
    path: 'map',
    component: MapComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'race',
    component: RaceComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'race-edit/:id',
    component: RaceComponent,
    canActivate: [authGuardFn]
  },
  {
    path: 'race-list',
    component: RaceListComponent
  },
  {
    path: 'race/:id',
    component: RaceViewComponent
  },
];
