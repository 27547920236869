import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeafletUtilsService } from 'src/app/services/leaflet-utils.service';

@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.css'
})
export class PopupComponent implements OnInit {
  @Input() customText: string
  @Input() speed: number
  @Input() riderId: string
  @Input() lat: number
  @Input() lon: number
  @Input() date: string
  @Input() battery: number
  followedRiderId: string

  constructor(
    private leafletUtilsService: LeafletUtilsService,
  ) { }

  ngOnInit() {
    this.followedRiderId = this.leafletUtilsService.getFollowedRider()
  }

  follow() {
    this.leafletUtilsService.followRider(this.riderId)
    this.followedRiderId = this.leafletUtilsService.getFollowedRider()
  }

  unFollow() {
    this.leafletUtilsService.unfollowRider()
    this.followedRiderId = this.leafletUtilsService.getFollowedRider()
  }

  reach() {
    const url = "https://maps.google.com/?q=" + this.lat + "," + this.lon;
    window.open(url);
  }

  formatDate(date: string) {
    return new Date(date).toLocaleString("fr-FR")
  }

  formatSpeed(speed: number) {
    return Math.round(speed * 3.6)
  }
}
