import { Component, OnInit } from '@angular/core';
import { CommonModule, formatDate } from '@angular/common';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { RaceService } from 'src/app/services/race.service';
import { MapComponent } from './../../../components/map/map.component';
import { Race } from 'src/app/domain/race';
import { ActivatedRoute, Router } from '@angular/router';
import { LeafletUtilsService } from 'src/app/services/leaflet-utils.service';
import { MatTabsModule } from '@angular/material/tabs';
import { Rider } from 'src/app/domain/rider';
import { v4 as uuidv4 } from 'uuid';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';

@Component({
  selector: 'app-race',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MapComponent,
    MatTabsModule,
    MatIconModule, 
    MatDividerModule
  ],
  templateUrl: './race.component.html',
  styleUrl: './race.component.scss'
})
export class RaceComponent implements OnInit {

  raceId=null
  dateFormat = "yyyy-MM-dd"
  language = "en"
  fileToUpload: File | null = null
  LatLngTuple!: [number,number][]
  riders: Rider[] = []
  
  raceForm = this.formBuilder.group({
    name: '',
    details: '',
    gpx: '',
    date: new FormControl(this.formatFormDate(new Date()))
  });

  constructor(
    private formBuilder: FormBuilder,
    private raceService: RaceService,
    private route: ActivatedRoute,
    private router: Router,
    private leafletUtilsService: LeafletUtilsService
  ) {}

  formatFormDate(date: Date) {
    return formatDate(date, this.dateFormat, this.language);
  }

  ngOnInit() {
    this.raceId = this.route.snapshot.paramMap.get('id');
    if(this.raceId) {
      this.raceService.getOne(this.raceId)
      .subscribe(race => {
        this.raceForm.patchValue({
          name: race.name,
          details: race.details,
          date: this.formatFormDate(race.date)
        })
        this.riders = race.riders
      })
      this.raceService.getCoordinates(this.raceId)
      .subscribe(coordinates => {
        if(coordinates.length) {
          this.LatLngTuple = coordinates
        }
      })
    }
  }

  delete() {
    if(confirm("Confirmer la suppression ? ")) {
      this.raceService.delete(this.raceId)
      .subscribe(race => {
        console.log("deleted")
        this.router.navigate(['race-list']);
      })
    }
  }

  async update(): Promise<void> {
    const race: Race = {
      _id: this.raceId,
      name: this.raceForm.controls.name.value,
      details: this.raceForm.controls.details.value,
      date: new Date(this.raceForm.controls.date.value)
    }
    race.riders = this.riders
    this.raceService.update(race)
    .subscribe(race => {
      console.log(race)
    })
    await this.saveGPX();
  }

  private async saveGPX() {
    const file = this.raceForm.controls.gpx.value;
    if (file) {
      const gpx = await this.fileToUpload.text();
      const coordinates = this.leafletUtilsService.getCoordinates(gpx);
      await this.raceService.uploadGPX(this.raceId, gpx, coordinates)
    }
  }

  async create(navigate=true) {
    const race: Race = {
      name: this.raceForm.controls.name.value,
      details: this.raceForm.controls.details.value,
      date: new Date(this.raceForm.controls.date.value)
    }
    race.riders = this.riders
    const _id = await this.raceService.create(race)
    this.raceId = _id
    await this.saveGPX()
    if(navigate) {
      this.router.navigate(['race',_id])
    }
  }

  async handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
    const text = await this.fileToUpload.text()
    this.LatLngTuple = this.leafletUtilsService.getCoordinates(text)
  } 

  addRider() {
    this.riders.unshift({
      _id: uuidv4(),
      name: "",
      firstname: "",
      bib: 0,
      code: "",
      team: ""
    })
  }

  removeRider(rider: Rider) {
    this.riders.splice(this.riders.findIndex(item => item._id === rider._id), 1)
  }

  back() {
    this.router.navigate(['race',this.raceId]);
  }

  async duplicate() {
    window.scrollTo(0, 0);
    this.raceForm.patchValue({name: this.raceForm.controls.name.value + " (copie)"}) 
    await this.create(false)
    this.router.navigate(['race-edit',this.raceId]);
  }
  
}
